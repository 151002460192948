import "./App.css";
import { MsalProvider } from "@azure/msal-react";
import { Home } from "./pages/Home";

function App({ msalInstance }) {
  document.body.style.backgroundColor = "black";
  return (
    <MsalProvider instance={msalInstance}>
      <Home />
    </MsalProvider>
  );
}

export default App;
