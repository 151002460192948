import { useMsal } from "@azure/msal-react";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  Modal,
  Typography,
} from "@mui/material";
import { useState } from "react";
import dataFetch from "../services/admin-data-fetcher";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 688,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  display: "flex",
  flexDirection: "column",
  borderRadius: "10px",
};

export default function DeleteAssistantModal({ assistant, handleClose }) {
  const { instance } = useMsal();
  const [isSure, setIsSure] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleDeleteAssistant = () => {
    if (isSure && assistant?.id != null) {
      setDeleteLoading(true);
      dataFetch
        .deleteAssistant(instance, assistant.id)
        .then((res) => {
          if (res.status !== 204) {
            res.data.then((message) => {
              setErrorMessage(message.split(".")[0]);
            });
          } else {
            setErrorMessage("");
          }
        })
        .finally(() => {
          setDeleteLoading(false);
          handleClose();
        });
    }
  };

  const handleCheck = (e) => {
    setIsSure(e.target.checked);
  };

  return (
    <div>
      <Modal
        open={true}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h5"
            component="h2"
            style={{ textAlign: "center" }}
          >
            Are you sure you want to delete your assistant {assistant?.name}?
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2 }}
            style={{ marginTop: 40, marginLeft: 12 }}
          >
            This will also delete associated documents for your assistant.
          </Typography>
          <Typography color="red">{errorMessage}</Typography>

          <FormControl>
            <Typography style={{ fontWeight: "bold" }}>
              <Checkbox
                checked={isSure}
                defaultChecked={false}
                onChange={(e) => handleCheck(e)}
                inputProps={{ "aria-label": "controlled" }}
                style={{ marginRight: 8, color: "#C8489F" }}
              />
              Yes, im sure.
            </Typography>
          </FormControl>

          <Button
            variant="outlined"
            style={{
              marginLeft: 12,
              marginBottom: 10,
              marginTop: 40,
              width: 120,
              height: 38,
              color: isSure ? "black" : "grey",
              borderColor: isSure ? "black" : "grey",
              textTransform: "none",
              fontWeight: "bold",
            }}
            onClick={handleDeleteAssistant}
            disabled={!isSure || deleteLoading}
          >
            Delete
          </Button>
        </Box>
      </Modal>
    </div>
  );
}
