const dataFetch = {
  async getAssistants(instance) {
    const user = instance.getActiveAccount();
    const request = {
      scopes: ["api://8c0837c8-c29c-4b80-9a55-f026b0d314b8/Api.Read"],
      account: user,
    };
    const token = await instance.acquireTokenSilent(request);
    const response = fetch(
      `https://datainsight-app.delightfulcliff-b669946a.westeurope.azurecontainerapps.io/assistant/get`,
      {
        method: "GET",
        mode: "cors",
        headers: new Headers({
          Authorization: "Bearer " + token.accessToken,
          "Content-Type": "application/json",
          Accept: "application/json",
        }),
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        return json;
      })
      .catch((err) => {
        console.error("error: ", err);
        return [];
      });
    return response;
  },
  async createAssistant(instance, assistantData) {
    const user = instance.getActiveAccount();
    const request = {
      scopes: ["api://8c0837c8-c29c-4b80-9a55-f026b0d314b8/Api.Read"],
      account: user,
    };
    const token = await instance.acquireTokenSilent(request);
    const response = fetch(
      `https://datainsight-app.delightfulcliff-b669946a.westeurope.azurecontainerapps.io/assistant/create`,
      {
        method: "POST",
        mode: "cors",
        body: JSON.stringify({
          name: assistantData.name,
          context: assistantData.context,
          language: assistantData.language,
        }),
        headers: new Headers({
          Authorization: "Bearer " + token.accessToken,
          "Content-Type": "application/json",
          Accept: "application/json",
        }),
      }
    )
      .then((res) => {
        return {
          status: res.status,
          data: res.status !== 204 ? res.json() : null,
        };
      })
      .then((json) => {
        return json;
      })
      .catch((err) => {
        console.error("error: ", err);
        return null;
      });
    return response;
  },
  async chatWithAssistant(
    apiKey,
    assistantId,
    message,
    history,
    promptHistory
  ) {
    const response = fetch(
      `https://datainsight-app.delightfulcliff-b669946a.westeurope.azurecontainerapps.io/conversation`,
      {
        method: "POST",
        mode: "cors",
        body: JSON.stringify({
          question: message,
          history: history,
          promptHistory: promptHistory,
        }),
        headers: new Headers({
          "x-api-key": apiKey,
          "x-assistant-id": assistantId,
          "Content-Type": "application/json",
          Accept: "application/json",
        }),
      }
    )
      .then((res) => {
        return {
          status: res.status,
          data: res.status !== 204 ? res.json() : null,
        };
      })
      .then((json) => {
        return json;
      })
      .catch((err) => {
        console.error("error: ", err);
        return null;
      });
    return response;
  },
  async chatWithAssistantDemo(
    instance,
    assistantId,
    message,
    history,
    promptHistory
  ) {
    const user = instance.getActiveAccount();
    const request = {
      scopes: ["api://8c0837c8-c29c-4b80-9a55-f026b0d314b8/Api.Read"],
      account: user,
    };
    const token = await instance.acquireTokenSilent(request);
    const response = fetch(
      `https://datainsight-app.delightfulcliff-b669946a.westeurope.azurecontainerapps.io/conversationdemo`,
      {
        method: "POST",
        mode: "cors",
        body: JSON.stringify({
          question: message,
          history: history,
          promptHistory: promptHistory,
        }),
        headers: new Headers({
          Authorization: "Bearer " + token.accessToken,
          "x-assistant-id": assistantId,
          "Content-Type": "application/json",
          Accept: "application/json",
        }),
      }
    )
      .then((res) => {
        return {
          status: res.status,
          data: res.status !== 204 ? res.json() : null,
        };
      })
      .then((json) => {
        return json;
      })
      .catch((err) => {
        console.error("error: ", err);
        return null;
      });
    return response;
  },
  async getAssistantDocuments(instance, assistantId) {
    const user = instance.getActiveAccount();
    const request = {
      scopes: ["api://8c0837c8-c29c-4b80-9a55-f026b0d314b8/Api.Read"],
      account: user,
    };
    const token = await instance.acquireTokenSilent(request);
    const response = fetch(
      `https://datainsight-app.delightfulcliff-b669946a.westeurope.azurecontainerapps.io/assistant/documents?assistantId=${assistantId}`,
      {
        method: "GET",
        mode: "cors",
        headers: new Headers({
          Authorization: "Bearer " + token.accessToken,
          "Content-Type": "application/json",
          Accept: "application/json",
        }),
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        return json;
      })
      .catch((err) => {
        console.error("error: ", err);
        return [];
      });
    return response;
  },
  async getAssistantExternalApi(instance, assistantId) {
    const user = instance.getActiveAccount();
    const request = {
      scopes: ["api://8c0837c8-c29c-4b80-9a55-f026b0d314b8/Api.Read"],
      account: user,
    };
    const token = await instance.acquireTokenSilent(request);
    const response = fetch(
      `https://datainsight-app.delightfulcliff-b669946a.westeurope.azurecontainerapps.io/assistant/externalapi?assistantId=${assistantId}`,
      {
        method: "GET",
        mode: "cors",
        headers: new Headers({
          Authorization: "Bearer " + token.accessToken,
          "Content-Type": "application/json",
          Accept: "application/json",
        }),
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        return json;
      })
      .catch((err) => {
        console.error("error: ", err);
        return null;
      });
    return response;
  },
  async deleteAssistant(instance, assistantId) {
    const user = instance.getActiveAccount();
    const request = {
      scopes: ["api://8c0837c8-c29c-4b80-9a55-f026b0d314b8/Api.Read"],
      account: user,
    };
    const token = await instance.acquireTokenSilent(request);
    const response = fetch(
      `https://datainsight-app.delightfulcliff-b669946a.westeurope.azurecontainerapps.io/assistant/delete?assistantId=${assistantId}`,
      {
        method: "DELETE",
        mode: "cors",
        headers: new Headers({
          Authorization: "Bearer " + token.accessToken,
          "Content-Type": "application/json",
          Accept: "application/json",
        }),
      }
    )
      .then((res) => {
        return {
          status: res.status,
          data: res.status !== 204 ? res.json() : null,
        };
      })
      .then((json) => {
        return json;
      })
      .catch((err) => {
        console.error("error: ", err);
        return null;
      });
    return response;
  },
  async uploadDocumentToAssistant(instance, assistantId, files) {
    const user = instance.getActiveAccount();
    const request = {
      scopes: ["api://8c0837c8-c29c-4b80-9a55-f026b0d314b8/Api.Read"],
      account: user,
    };
    const token = await instance.acquireTokenSilent(request);

    const data = new FormData();
    for (const file of files) {
      console.log("appending: ", file);
      data.append("files", file, file.name);
    }
    const response = fetch(
      `https://datainsight-app.delightfulcliff-b669946a.westeurope.azurecontainerapps.io/assistant/upload?assistantId=${assistantId}`,
      {
        method: "POST",
        mode: "cors",
        body: data,
        headers: new Headers({
          Authorization: "Bearer " + token.accessToken,
          Accept: "application/json",
        }),
      }
    )
      .then((res) => {
        return {
          status: res.status,
          data: res.status !== 201 ? res.json() : null,
        };
      })
      .then((json) => {
        console.log("json: ", json);
        return json;
      })
      .catch((err) => {
        console.error("error: ", err);
        return null;
      });
    return response;
  },
};
export default dataFetch;
