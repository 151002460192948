import { Button, Typography } from "@mui/material";
import { useMsal } from "@azure/msal-react";

export const SignInButton = () => {
  const { instance } = useMsal();
  const handleSignIn = () => {
    instance.loginRedirect({
      scopes: ["user.read"],
    });
  };
  return (
    <Button
      color="inherit"
      onClick={handleSignIn}
      style={{
        minWidth: "250px",
        minHeight: "50px",
        background: "black",
        color: "white",
        marginTop: 55,
        borderRadius: "10px",
      }}
    >
      <Typography>SIGN IN</Typography>
    </Button>
  );
};
