import { Box, Paper, Typography } from "@mui/material";
import { SignInButton } from "../components/SignInButton";
import logo from "../assets/insai-logo-start.png";
import start from "../assets/insai-text-start.png";

export const SignIn = () => {
  return (
    <Paper
      style={{
        width: "327px",
        margin: "auto",
        marginTop: "200px",
        minHeight: "446px",
        borderRadius: "10px",
      }}
    >
      <Box textAlign="center">
        <img
          src={logo}
          alt="Insai logo"
          style={{ maxWidth: "100%", height: "auto", marginTop: 45 }}
        />
        <br />
        <img
          src={start}
          alt="Insai logo"
          style={{
            maxWidth: "100%",
            height: "auto",
            marginTop: 10,
            marginBottom: 10,
          }}
        />
        <br />
        <Typography>DATA INSIGHTS PLATFORM</Typography>
        <SignInButton />
      </Box>
    </Paper>
  );
};
