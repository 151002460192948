import * as React from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import LogoutIcon from "@mui/icons-material/Logout";

import logo from "../assets/insai-logo-header.png";
import logoAssistant from "../assets/insai-assistant.png";
import logoDocumentAnalyzer from "../assets/insai-documents.png";
import { Avatar } from "@mui/material";
import { useMsal } from "@azure/msal-react";
import { useState, useEffect } from "react";
import { HomeAssistant } from "../pages/HomeAssistant";
import dataFetch from "../services/admin-data-fetcher";
import { HomeAnalyzer } from "../pages/HomeAnalyzer";

const drawerWidth = 240;

export default function MenuDrawer() {
  document.body.style.backgroundColor = "rgba(236, 236, 236, 0.4)";
  const { instance, accounts } = useMsal();
  const [user, setUser] = useState({});
  const [page, setPage] = useState(0);
  const [companyName, setCompanyName] = useState("");

  let viewPage;

  if (page === 0) {
    viewPage = <HomeAssistant />;
  } else if (page === 1) {
    viewPage = <HomeAnalyzer />;
  }

  const handleSignOut = () => {
    instance.logout();
  };

  useEffect(() => {
    const user = instance.getActiveAccount();
    if (user) {
      setUser(user);
      setCompanyName(getCompanyName(user.username));
    }
  }, [instance, accounts]);

  function getCompanyName(username) {
    return username.includes("@") ? username.split("@")[1] : username;
  }

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: `100%`,
          ml: `${drawerWidth}px`,
          bgcolor: "navy",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <Grid container style={{ backgroundColor: "black", padding: "10px" }}>
          <Grid item xs={2}>
            <img
              variant={"rounded"}
              src={logo}
              alt="insai logo"
              style={{
                paddingTop: "10px",
                paddingLeft: "25px",
              }}
            />
          </Grid>
          <Grid item xs={8}>
            <Typography
              variant="h6"
              noWrap
              component="div"
              style={{ paddingTop: "15px" }}
            >
              Hello, {user.name} ({companyName})
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Button
              style={{ paddingTop: "15px", color: "white", float: "right" }}
              onClick={handleSignOut}
            >
              Sign out{" "}
              <LogoutIcon style={{ paddingLeft: "10px", float: "right" }} />
            </Button>
          </Grid>
        </Grid>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
          zIndex: (theme) => theme.zIndex.drawer - 1,
        }}
        variant="permanent"
        anchor="left"
      >
        <Divider />
        <List style={{ marginTop: "4.5rem" }}>
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => {
                dataFetch.getAssistants(instance);
                setPage(0);
              }}
            >
              <Avatar
                variant={"rounded"}
                src={logoAssistant}
                style={{
                  width: 34,
                  height: 34,
                }}
              />
              <ListItemText
                style={{ paddingLeft: "1rem" }}
                primary={"Assistant"}
              />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => {
                console.log("clicked classification!");
                setPage(1);
              }}
            >
              <Avatar
                variant={"square"}
                src={logoDocumentAnalyzer}
                style={{
                  width: 28,
                  height: 34,
                }}
              />
              <ListItemText
                style={{ paddingLeft: "1.5rem" }}
                primary={"Document Analyzer"}
              />
            </ListItemButton>
          </ListItem>
        </List>
        <List
          style={{ position: "absolute", bottom: "0", right: "0", left: "0" }}
        >
          <ListItem style={{ paddingBottom: "0px" }}>
            <Typography noWrap component="div">
              INSAI
            </Typography>
          </ListItem>
          <ListItem style={{ paddingTop: "0px" }}>
            <Typography noWrap component="div">
              Powered by
            </Typography>
            <a
              href={"https://avantime.se"}
              style={{ color: "black", paddingLeft: "3px" }}
            >
              Avantime
            </a>
          </ListItem>
        </List>
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          bgcolor: "background.default",
          p: 3,
          backgroundColor: "rgba(236, 236, 236, 0.2)",
        }}
      >
        <Toolbar style={{ marginTop: "10px" }} />
        {viewPage}
      </Box>
    </Box>
  );
}
