import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { useMsal } from "@azure/msal-react";
import { useState, useEffect } from "react";
import MenuDrawer from "../components/MenuDrawer";
import { SignIn } from "./SignIn";

export const Home = () => {
  const { instance } = useMsal();
  const [user, setUser] = useState({});

  useEffect(() => {
    if (!user) {
      const user = instance.getActiveAccount();
      if (user) {
        console.log("logged in: ", user);
        setUser(user);
      }
    }
  }, [user, instance]);
  return (
    <>
      <AuthenticatedTemplate>
        <MenuDrawer />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <SignIn />
      </UnauthenticatedTemplate>
    </>
  );
};
